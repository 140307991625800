import { Component } from '@angular/core';
import { QuickBookService } from '../../services/quickbook.service';


@Component({
  selector: 'app-quickbook-connect',
  templateUrl: './quickbook-connect.component.html',
  styleUrl: './quickbook-connect.component.scss'
})
export class QuickBookConnectComponent {
  constructor(private quickBookService: QuickBookService) {}

  goLogin() {
    this.quickBookService.goLogin();
  }  
}
