import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import _ from 'lodash';
import { Observable, of, tap, catchError } from 'rxjs';
import { EnvService } from '../../services/env.service';
import { ReferenceData } from '../interfaces/reference-data';

@Injectable({
  providedIn: 'root'
})
export class ReferencesService {
  private _refData?: ReferenceData;
  constructor(private http: HttpClient, private env: EnvService) {}

  load(): Observable<ReferenceData> {
    if (this._refData) {
      return of(this._refData);
    }

    return this.http.get<ReferenceData>(`${this.env.config.apiUrl}/admin/references`)
      .pipe(
        tap((data: ReferenceData) => {
          this._refData = data;
        })
      );
  }

  get data(): ReferenceData | undefined {
    return this._refData;
  }
}

