<div class="page-container">
    <div class="page-content">
        <div class="welcome-content">
            <label class="lable">Welcome</label>
            <div class="store-name">{{ 'Admin Back Office' }}</div>
        </div>
        <nav class="tab">
            <a class="tab-item">
                <div class="icon"></div>
                <span class="text">Order List ({{ orders.length }})</span>
            </a>
        </nav>
        @if (orders.length > 0) {
            <div class="order-list">
                @for (order of orders;track $index) {
                <div class="restaurant-content" (click)="viewOrderDetail(order)">
                    <div class="field-display-group">
                        <div class="chip">{{ order.status }}</div>
                        <label class="label">Order ID :</label>
                        <p class="value">{{ order.id }}</p>
                    </div>
                    <div class="field-display-group">
                        <label class="label">Total amount :</label>
                        <p class="value">{{ order.totalAmount | currency }}</p>
                    </div>
                </div>
                }
            </div>
        }
        @else {
            <div class="order-emptyp">
                <div class="icon-box">
                    <img class="icon" src="assets/images/icons/icon_archive.svg">
                </div>
                <p class="desciption">Order is Empty</p>
            </div>
        }
    </div>
</div>