import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject, takeUntil } from 'rxjs';
import { Order } from '../../../interfaces/order';
import { CommonModule } from '@angular/common';
import { AdminOrderService } from '../../services/admin-order.service';
import { SessionStorageService } from '../../../services/session-storage.service';

@Component({
  selector: 'app-admin-order-detail',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './admin-order-detail.component.html',
  styleUrl: './admin-order-detail.component.scss'
})
export class AdminOrderDetailComponent {
  orderId: number;
  order: Order | undefined;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private orderService: AdminOrderService,
    private storageService: SessionStorageService
  ) {
    const orderIdStr = this.activatedRoute.snapshot.paramMap.get('id');
    this.orderId = Number.parseInt(orderIdStr || '0', 10);
  }

  ngOnInit(): void {
    this.getOrder(this.orderId);
  }

  getOrder(orderId: number): void {
    this.orderService.get(orderId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (order: Order) => {
          this.order = order;
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => {
        }
      });
  }

  createInvoice(): void {
    this.orderService.createInvoice(this.orderId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (_: Order) => {
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => {
          this.storageService.setItem('successPage', JSON.stringify({
            label: 'Invoice Created',
            buttonName: 'Back to Home',
            nextPath: '/admin/orders'
          }));
          this.router.navigate(['/success'])
        }
      });
  }
}
