@if (customer) {
<div class="page-container">
    <div class="page-content">
        <div class="restaurant-content">
            <div class="field-display-group">
                <label class="label">Restaurant Name :</label>
                <p class="value">{{ customer.restaurant.name }}</p>
            </div>
            <div class="field-display-group column">
                <label class="label">Shipping Detail :</label>
                <div class="value-group">
                    <p class="value">Contact person: {{ customer.name }}</p>
                    <p class="value">Telephone: {{ customer.phoneNo || '-' }}</p>
                </div>
            </div>
        </div>
        <div class="product-list">
            @for (product of products; track product) {
            <div class="product-row">
                <div class="image"></div>
                <p class="name">{{ product.name }}</p>
                <p class="price">{{ product.price | currency }}</p>
                <p class="quantity">x{{product.quantity }}</p>
            </div>
            }
        </div>
    </div>
    <div class="page-bottom-action">
        <div class="total-price">Total {{ getTotal() | currency }}</div>
        <button class="button-secondary" (click)="confirmOrder()">Confirm Order</button>
    </div>
</div>
}