@if (profile && customer) {
<div class="page-container">
    <div class="page-content">
        <div class="welcome-content">
            <label class="lable">Welcome</label>
            <div class="store-name">{{ customer.restaurant.name }}</div>
        </div>
        <nav class="tab">
            <a class="tab-item" (click)="onSwitchTap(orderTab.ORDER)" [ngClass]="{active: tab===orderTab.ORDER}">
                <div class="icon"></div>
                <span class="text">My Order</span>
            </a>
            <a class="tab-item" (click)="onSwitchTap(orderTab.ORDER_HISTORY)"
                [ngClass]="{active: tab===orderTab.ORDER_HISTORY}">
                <div class="icon"></div>
                <span class="text">History</span>
            </a>
        </nav>
        @if (tab === orderTab.ORDER) {
        @if (orders.length > 0) {
        <div class="order-list">
            @for (order of orders;track $index) {
            <div class="restaurant-content" (click)="viewOrderDetail(order)">
                <div class="field-display-group">
                    <div class="chip">{{ order.status | titlecase }}</div>
                    <label class="label">Order ID :</label>
                    <p class="value">{{ order.id }}</p>
                </div>
                <div class="field-display-group">
                    <label class="label">Total amount :</label>
                    <p class="value">{{ order.totalAmount | currency }}</p>
                </div>
            </div>
            }
        </div>
        }
        @else {
        <div class="order-emptyp">
            <div class="icon-box">
                <img class="icon" src="assets/images/icons/icon_archive.svg">
            </div>
            <p class="desciption">Your Order is Empty</p>
        </div>
        }
        }
        @if (tab === orderTab.ORDER_HISTORY) {
        @if (orders.length > 0) {
        <div class="order-list">
            @for (order of orders;track $index) {
            <div class="restaurant-content" (click)="viewOrderDetail(order)">
                <div class="field-display-group">
                    <div class="chip">{{ order.status | titlecase }}</div>
                    <label class="label">Order ID :</label>
                    <p class="value">{{ order.id }}</p>
                </div>
                <div class="field-display-group">
                    <label class="label">Total amount :</label>
                    <p class="value">{{ order.totalAmount | currency }}</p>
                </div>
            </div>
            }
        </div>
        }
        @else {
        <div class="order-emptyp">
            <div class="icon-box">
                <img class="icon" src="assets/images/icons/icon_archive.svg">
            </div>
            <p class="desciption">Your Order is Empty</p>
        </div>
        }
        }
    </div>
    <div class="page-bottom-action">
        <button class="button-secondary" (click)="navigateToProduct()">New Order</button>
    </div>
</div>
}