import { Injectable } from '@angular/core';
import { Product } from '../../interfaces/product';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvService } from '../../services/env.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient, private env: EnvService) { }

  syncProducts(): Observable<Product[]> {
    const url = `${this.env.config.apiUrl}/admin/sync/products`;
    return this.http.post<Product[]>(url, null);
  }
}
