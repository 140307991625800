import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from '../../interfaces/product';
import { CommonModule } from '@angular/common';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReplaySubject, takeUntil } from 'rxjs';
import { LIFFExtendedProfile } from '../../interfaces/liff-extended-profile';
import { EnvService } from '../../services/env.service';
import { LiffService } from '../../services/liff.service';
import { SelectedProduct } from '../../interfaces/selected-product';
import { ProductService } from '../../services/product.service';
import { SessionStorageService } from '../../services/session-storage.service';

@Component({
  selector: 'app-product-list',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './product-list.component.html',
  styleUrl: './product-list.component.scss'
})
export class ProductListComponent {
  public profile!: LIFFExtendedProfile;
  products: SelectedProduct[] = [];
  selectedProducts: SelectedProduct[] = [];
  summaryPrice: number = 0;
  productForm = new FormGroup({
    products: new FormArray<FormGroup>([])
  })
  hasSelectedProducts: SelectedProduct[] = [];

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private router: Router,
    private liffService: LiffService,
    private env: EnvService,
    private productService: ProductService,
    private storageService: SessionStorageService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.liffService.init$(this.env.config.liffApps['product-list'])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (profile: LIFFExtendedProfile) => {
          this.profile = profile;
          this.getProductList('');
        },
        error: (e) => {
          if (!this.liffService.isLoggedIn()) {
            this.liffService.login('/products');
          }
        },
        complete: () => {
        }
      })
  }

  getProductList(search: string) {
    this.productService.getProduct(this.profile.accessToken!, search)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (products: Product[]) => {
          if (products) {
            this.products = products.map((p: Product) => {
              return {
                id: p.id,
                name: p.name,
                price: p.price,
                quantity: 0
              } as SelectedProduct;
            })
            this.createForm(this.products);
          }
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => {
        }
      });
  }

  createForm(productList: SelectedProduct[]) {
    const formArray = new FormArray<FormGroup>([])
    productList.forEach((product) => {
      const formName = new FormGroup({
        [product.id]: new FormGroup({
          quantity: new FormControl()
        })
      })
      formArray.push(formName)
      const selectedProduct = this.selectedProducts.find(p => p.id === product.id);
      if (!selectedProduct) {
        this.selectedProducts.push(product);
      }
    })
    this.productForm = this.formBuilder.group({
      products: formArray
    })
    this.formArrayChanges(formArray)
  }

  formArrayChanges(formArray: FormArray) {
    formArray.valueChanges.subscribe(products => {
      products.forEach((product: any) => {
        const selectedProduct = this.selectedProducts.find(p => p.id === Number(Object.keys(product)[0]));
        if (selectedProduct && product[Object.keys(product)[0]].quantity) {
          selectedProduct.quantity = product[Object.keys(product)[0]].quantity;
        } else if (selectedProduct && product[Object.keys(product)[0]].quantity === null) {
          selectedProduct.quantity = 0;
        }
      })
      this.hasSelectedProducts = this.selectedProducts.filter(value => value.quantity !== null && value.quantity !== 0)
      this.calculateSummaryPrice();
    });
  }

  increaseQuantity(product: SelectedProduct) {
    product.quantity++;
    const selectedProduct = this.selectedProducts.find(p => p.id === product.id);
    const findProductForm = this.productForm.controls.products.controls.find(formProduct =>  Number(Object.keys(formProduct.controls)[0]) === product.id)
    findProductForm?.get(`${product.id}`)?.get('quantity')?.setValue(product.quantity) 
    if (selectedProduct) {
      selectedProduct.quantity = product.quantity;
    }
  }

  decreaseQuantity(product: SelectedProduct) {
    if (product.quantity > 0) {
      product.quantity--;
      const findProductForm = this.productForm.controls.products.controls.find(formProduct =>  Number(Object.keys(formProduct.controls)[0]) === product.id)
      if (product.quantity === 0) {
        findProductForm?.get(`${product.id}`)?.get('quantity')?.setValue(product.quantity) 
      } else {
        const selectedProduct = this.selectedProducts.find(p => p.id === product.id);
        if (selectedProduct) {
          selectedProduct.quantity = product.quantity;
          findProductForm?.get(`${product.id}`)?.get('quantity')?.setValue(product.quantity) 
        }
      }
    }
  }

  calculateSummaryPrice() {
    this.summaryPrice = this.selectedProducts.reduce((summary, { price, quantity }) => {
      return summary + (price * quantity)
    }, 0)
  }

  checkout() {
    this.storageService.setItem('selectedProducts', JSON.stringify(this.hasSelectedProducts));
    this.router.navigate(['/order/checkout']);
  }

  onEnterSearch(event: any) {
    this.getProductList(event.target.value)
  }
}