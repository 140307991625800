import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, of, Subject } from 'rxjs';
import { LIFFExtendedProfile } from '../interfaces/liff-extended-profile';
import liff from '@line/liff';
import { LIFFFriendFlag } from '../interfaces/liff-friendflag';
import LIFFProfile from '../interfaces/liff-profile';
import { LiffError } from '@liff/util';
// import querystring from 'query-string';
import querystring from 'qs';
import { EnvService } from './env.service';
import { WindowService } from './window.service';

@Injectable({
  providedIn: 'root'
})
export class LiffService {
  private profileSubject: Subject<LIFFExtendedProfile>;

  constructor(
    private envService: EnvService,
    private windowService: WindowService
  ) {
    this.profileSubject = new Subject<LIFFExtendedProfile>();
  }

  init$(liffId: string): Observable<LIFFExtendedProfile> {
    // init$
    // Initialize LIFF Session
    // - Initialize LIFF API
    // - Check if user logged in
    // - Check friendship between user and Messaging API
    // return of({ accessToken: 'eyJhbGciOiJIUzI1NiJ9.Z019QVj9EvthG0KzA8gLxl5ktlZtbxhMrvwErSpOj_tPP5Lx4FmXOJN4sMZSBHuGFS0jktql21sGfTK5gcZWnviaQLkMAn1upNUmK6nDNJXA0CRqFzE2sJfHEmdMmukMi1hHilIaPcKkdRUrwyAPawuo5lUsJ8fCc3xyILMuXtM.xnFk_13vKDkcaASCtMTuU96p0T5tWVUIQVvEFi6_Cc8', userId: '', pictureUrl: '', displayName: '' } as LIFFExtendedProfile);
    liff.init({
      liffId: liffId
    }).then(() => {
      if (liff.isLoggedIn()) {
        const accessToken = liff.getAccessToken();
        liff.getFriendship().then((data: LIFFFriendFlag) => {
          if (data.friendFlag) {
            liff.getProfile()
              .then((profile: LIFFProfile) => {
                this.profileSubject.next(_.extend({}, profile, {accessToken: accessToken}));
              })
              .catch((err) => {
                this.profileSubject.error(err);
              });
          } else {
            console.log("go add friend");
            // Add friend first
            this.windowService.redirect(this.envService.config.redirectTo);
          }
        }).catch((err: LiffError) => {
          this.profileSubject.error(err);
        });
      } else {
        this.profileSubject.error(new Error('user is not logged in'));
      }
    }).catch((err) => {
      this.profileSubject.error(err);
    });

    return this.profileSubject.asObservable();
  }

  isLoggedIn(): boolean {
    return liff.isLoggedIn();
  }

  login(page: string, params?: { [key: string]: string; }): void {
    let qs = '';
    if (!!params) {
      qs = querystring.stringify(params);
    }

    liff.login({redirectUri: `${this.envService.config.homeUrl}${page}?${qs}`});
  }

  logout(): void {
    liff.logout();
  }

  isInLine(): boolean {
    return liff.isInClient();
  }

  close(): void {
    liff.closeWindow();
  }

  get accessToken(): string | null {
    return liff.getAccessToken()
  }
}
