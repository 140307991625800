import { Component } from '@angular/core';
import { Product } from '../../../interfaces/product';
import { ReplaySubject, takeUntil } from 'rxjs';
import { AdminService } from '../../services/admin.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-admin-products-sync',
  standalone: true,
  imports: [ CommonModule ],
  templateUrl: './admin-products-sync.component.html',
  styleUrl: './admin-products-sync.component.scss'
})
export class AdminProductsSyncComponent {
  products: Product[] = [];
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private adminService: AdminService,
  ) {}

  sync() {
    this.adminService.syncProducts()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (products: Product[]) => {
          this.products = products;
        },
        error: () => {

        }
      });
  }
}
