import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject, takeUntil } from 'rxjs';
import { LIFFExtendedProfile } from '../../interfaces/liff-extended-profile';
import { EnvService } from '../../services/env.service';
import { LiffService } from '../../services/liff.service';
import { Customer } from '../../interfaces/customer';
import { CustomerService } from '../../services/customer.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
  selector: 'app-customer-profile',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule
  ],
  templateUrl: './customer-profile.component.html',
  styleUrl: './customer-profile.component.scss'
})
export class CustomerProfileComponent {
  public profile!: LIFFExtendedProfile;
  public customer: Customer | undefined;
  
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private router: Router,
    private liffService: LiffService,
    private env: EnvService,
    private customerService: CustomerService,
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state && navigation.extras.state['customer']) {
      this.customer = navigation.extras.state['customer'];
    }
  }
  
  ngOnInit(): void {
    this.liffService.init$(this.env.config.liffApps['profile'])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (profile: LIFFExtendedProfile) => {
          this.profile = profile;
          if(!this.customer) {
            this.fetchProfile(profile.accessToken!)
          }
        },
        error: (e) => {
          if (!this.liffService.isLoggedIn()) {
            this.liffService.login('/customer/profile');
          }
        },
        complete: () => {
        }
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  
  fetchProfile(accessToken: string) {
    this.customerService.get(accessToken)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (customer: Customer) => {
          this.customer = customer;
        },
        error: (e) => {            
          console.log(e);
        },
        complete: () => {
        }
      });
  }
}