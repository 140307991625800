import { HttpInterceptorFn, HttpErrorResponse, HttpRequest, HttpEvent } from "@angular/common/http";
import { inject } from "@angular/core";
import { Subject, Observable, tap, catchError, of, switchMap, throwError } from "rxjs";
import { RefreshTokenRequest } from "../../interfaces/refresh-token-request";
import { QuickbookService } from "../services/quickbook.service";

export const authInterceptor: HttpInterceptorFn = (request, next) => {
  const qbService = inject(QuickbookService);

  const handleResponseError = (error: HttpErrorResponse, request: HttpRequest<any>, next: (req: HttpRequest<any>) => Observable<HttpEvent<any>>): Observable<any> => {
    const refreshToken = (): Observable<any> => {
      let refreshTokenInProgress = false;
      const tokenRefreshedSource = new Subject<any>();

      if (refreshTokenInProgress) {
        return new Observable((observer) => {
          tokenRefreshedSource.asObservable().subscribe(() => {
            observer.next();
            observer.complete();
          });
        });
      } else {
        refreshTokenInProgress = true;
        const refreshTokenValue = qbService.token?.refreshToken;

        return qbService.refreshToken({ refreshToken: refreshTokenValue } as RefreshTokenRequest).pipe(
          tap((value) => {
            refreshTokenInProgress = false;
            tokenRefreshedSource.next(value);
          }),
          catchError(() => {
            refreshTokenInProgress = false;
            return of({});
          })
        );
      }
    };

    if (isAdminRequest(request) && (error.status === 401 || error.error?.message === 'missing or malformed jwt')) {
      return refreshToken().pipe(
        switchMap((value) => {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${value.accessToken}`,
            },
          });
          return next(request);
        }),
        catchError((e) => {
          if (e.status !== 401) {
            return handleResponseError(e, request, next);
          } else {
            return of({});
          }
        })
      );
    }

    return throwError(() => error);
  };

  const isAdminRequest = (request: HttpRequest<any>) => {
    return request.url.search('/admin') > -1;
  }

  const isAdminAuthen = (request: HttpRequest<any>) => {
    return request.url.endsWith('admin/authen');
  }

  if (isAdminRequest(request)) {
    if (isAdminAuthen(request)) {
      return next(request).pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
    }

    const accessToken = qbService.token?.accessToken;
    if (accessToken) {
      request = request.clone({
        setHeaders: {
          'X-Access-Token': `${accessToken}`,
        },
      });
    }
  }

  return next(request).pipe(
    catchError((error) => handleResponseError(error, request, next))
  );
};