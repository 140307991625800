<router-outlet />
<!-- @if (!shouldHideBottomNav()) {
<div class="bottom-nav">
    <a routerLink="/products">
        <mat-icon>restaurant_menu</mat-icon>
        <span>Products</span>
    </a>
    <a routerLink="/order">
        <mat-icon>library_books</mat-icon>
        <span>My Orders</span>
    </a>
    <a routerLink="customer/profile">
        <mat-icon>account_circle</mat-icon>
        <span>Profile</span>
    </a>
</div>
} -->