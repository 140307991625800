@if (profile) {
<div class="page-container">
    <div class="page-content">
        <form class="form-group" [formGroup]="registerForm">
            <div class="field-group">
                <label class="lable">Restaurant Name</label>
                <input class="input-field" formControlName="restaurantName" placeholder="Enter restaurant name...">
            </div>
            <div class="field-group">
                <label class="lable">Contact Name</label>
                <input class="input-field" formControlName="name" placeholder="Enter contact name...">
            </div>
            <div class="field-group">
                <label class="lable">Telephone</label>
                <input class="input-field" formControlName="phoneNo" placeholder="Enter telephone...">
            </div>
        </form>
    </div>
    <div class="page-bottom-action">
        <button class="button-secondary" type="submit" (click)="onSubmit()"
            [disabled]="this.registerForm.invalid">Submit</button>
    </div>
</div>
}