import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject, takeUntil } from 'rxjs';
import { LIFFExtendedProfile } from '../../interfaces/liff-extended-profile';
import { EnvService } from '../../services/env.service';
import { LiffService } from '../../services/liff.service';
import { OrderService } from '../../services/order.service';
import { Order } from '../../interfaces/order';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { OrderStatus } from '../order/order.component';

@Component({
  selector: 'app-order-status',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatDividerModule
  ],
  templateUrl: './order-status.component.html',
  styleUrl: './order-status.component.scss'
})
export class OrderStatusComponent {
  orderId: number | undefined;
  order: Order | undefined;
  orderStatus = OrderStatus

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private activatedRoute: ActivatedRoute,
    private liffService: LiffService,
    private orderService: OrderService,
    private router: Router,
  ) {
    const liffState = this.activatedRoute.snapshot.queryParamMap.get('liff.state');
    const orderIdStr = liffState ? new URLSearchParams(liffState).get('id') : this.activatedRoute.snapshot.queryParamMap.get('id');
    if (orderIdStr) {
      this.orderId = Number.parseInt(orderIdStr || '0', 10);
    }
  }

  ngOnInit(): void {
    if(this.orderId) {
      this.getOrder(this.orderId);
    }
  }

  getOrder(orderId: number): void {
    this.orderService.get(this.liffService.accessToken!, orderId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (order: Order) => {
          this.order = order;
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => {
        }
      });
  }

  pickupOrder(orderId: number): void {
    this.orderService.pickup(this.liffService.accessToken!, orderId)
    .subscribe({
      next: () => {
        this.router.navigate(['/order']);
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => {
      }
    });
  }
}
