import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService } from './env.service';
import { OrderRequest } from '../interfaces/order-request';
import { Order } from '../interfaces/order';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient, private env: EnvService) { }

  public getOrders(customerAccessToken: string, status: string): Observable<Order[]> {
    const headers: { [header: string]: string } = this.getHttpHeaders(customerAccessToken);
    return this.http.get<Order[]>(`${this.env.config.apiUrl}/orders?status=${status}`, {
      headers
    });
  }

  public get(customerAccessToken: string, orderId: number): Observable<Order> {
    const headers: { [header: string]: string } = this.getHttpHeaders(customerAccessToken);
    return this.http.get<Order>(`${this.env.config.apiUrl}/orders/${orderId}`, {
      headers
    });
  }

  public submit(customerAccessToken: string, request: OrderRequest[]): Observable<Order> {
    const headers: { [header: string]: string } = this.getHttpHeaders(customerAccessToken);
    return this.http.post<Order>(`${this.env.config.apiUrl}/orders/checkout`, request, {
      headers
    });
  }

  public pickup(customerAccessToken: string, orderId: number): Observable<Order> {
    const headers: { [header: string]: string } = this.getHttpHeaders(customerAccessToken);
    return this.http.put<Order>(`${this.env.config.apiUrl}/orders/${orderId}`, {}, {
      headers
    });
  }

  private getHttpHeaders(customerAccessToken: string): { [header: string]: string } {
    return {
      'X-Line-Channel-ID': this.env.config.liffChannelId,
      'X-Line-Access-Token': customerAccessToken
    };
  }
}
