import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from '../../interfaces/product';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ReplaySubject, takeUntil } from 'rxjs';
import { LIFFExtendedProfile } from '../../interfaces/liff-extended-profile';
import { EnvService } from '../../services/env.service';
import { LiffService } from '../../services/liff.service';
import { MatButtonModule } from '@angular/material/button';
import {MatListModule} from '@angular/material/list';
import {MatDividerModule} from '@angular/material/divider';
import { OrderService } from '../../services/order.service';
import { OrderRequest } from '../../interfaces/order-request';
import { SelectedProduct } from '../../interfaces/selected-product';
import { SessionStorageService } from '../../services/session-storage.service';
import { Customer } from '../../interfaces/customer';
import { CustomerService } from '../../services/customer.service';

@Component({
  selector: 'app-order',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatDividerModule
  ],
  templateUrl: './order-checkout.component.html',
  styleUrl: './order-checkout.component.scss'
})
export class OrderCheckoutComponent {
  products: SelectedProduct[] = [];
  public customer: Customer | undefined;
  
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private router: Router,
    private liffService: LiffService,
    private orderService: OrderService,
    private storageService: SessionStorageService,
    private customerService: CustomerService,
  ) {
    const productsString = this.storageService.getItem('selectedProducts');
    if (productsString) {
      this.products = JSON.parse(productsString);
    }
  }
  
  ngOnInit(): void {
    if(!this.customer) {
      this.fetchProfile(this.liffService.accessToken!)
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getTotal(): number {
    return this.products.reduce((total, product) => total + product.price * product.quantity, 0);
  }

  confirmOrder() {
    if(this.products.length < 1) {
      return;
    }

    const request = this.products.map((product: SelectedProduct) => {
      return {
        productId: product.id, 
	      quantity: product.quantity,
      } as OrderRequest;
    });
    this.orderService.submit(this.liffService.accessToken!, request)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.storageService.removeItem('selectedProducts');
          this.storageService.setItem('successPage', JSON.stringify({
            label: 'Success',
            buttonName: 'Track My Order',
            nextPath: '/order'
          }));
          this.router.navigate(['/success']);
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => {
        }
      });
  }

  fetchProfile(accessToken: string) {
    this.customerService.get(accessToken)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (customer: Customer) => {
          this.customer = customer;
        },
        error: (e) => {            
          console.log(e);
        },
        complete: () => {
        }
      });
  }
}
