import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService } from '../../services/env.service';
import { Order } from '../../interfaces/order';

@Injectable({
  providedIn: 'root'
})
export class AdminOrderService {

  constructor(private http: HttpClient, private env: EnvService) { }

  get(orderId: number): Observable<Order> {
    const url = `${this.env.config.apiUrl}/admin/orders/${orderId}`;
    return this.http.get<Order>(url);
  }

  getAll(): Observable<Order[]> {
    const url = `${this.env.config.apiUrl}/admin/orders`;
    return this.http.get<Order[]>(url);
  }

  createInvoice(orderId: number): Observable<Order> {
    const url = `${this.env.config.apiUrl}/admin/orders/${orderId}/invoice`;
    return this.http.post<Order>(url, null);
  }

}
