import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService } from './env.service';
import { Product } from '../interfaces/product';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient, private env: EnvService) { }

  public getProduct(customerAccessToken: string): Observable<Product[]> {
    const headers: { [header: string]: string } = this.getHttpHeaders(customerAccessToken);
    return this.http.get<Product[]>(`${this.env.config.apiUrl}/products`, {
      headers
    });
  }

  private getHttpHeaders(customerAccessToken: string): { [header: string]: string } {
    return {
      'X-Line-Channel-ID': this.env.config.liffChannelId,
      'X-Line-Access-Token': customerAccessToken
    };
  }
}
