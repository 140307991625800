import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { debounceTime, ReplaySubject, Subject, takeUntil } from 'rxjs';
import { LIFFExtendedProfile } from '../../interfaces/liff-extended-profile';
import { EnvService } from '../../services/env.service';
import { LiffService } from '../../services/liff.service';
import { SelectedProduct } from '../../interfaces/selected-product';
import { ProductService } from '../../services/product.service';
import { SessionStorageService } from '../../services/session-storage.service';

@Component({
  selector: 'app-product-list',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './product-list.component.html',
  styleUrl: './product-list.component.scss'
})
export class ProductListComponent implements OnInit, OnDestroy {
  public profile!: LIFFExtendedProfile;

  products: SelectedProduct[] = [];
  filteredProducts: SelectedProduct[] = [];
  selectedProductsMap = new Map<number, SelectedProduct>();
  summaryPrice = 0;
  hasSelectedProducts: SelectedProduct[] = [];

  searchInput = new Subject<string>();

  private destroy$ = new ReplaySubject<boolean>(1);

  searchText: string = '';

  constructor(
    private router: Router,
    private liffService: LiffService,
    private env: EnvService,
    private productService: ProductService,
    private storageService: SessionStorageService
  ) {
    this.searchInput
      .pipe(
        debounceTime(300),
        takeUntil(this.destroy$)
      )
      .subscribe((searchTerm: string) => {
        this.filterProducts(searchTerm)
      });
  }

  ngOnInit(): void {
    this.getProduct();
  }

  getProduct() {
    this.liffService.init$(this.env.config.liffApps['product-list'])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (profile) => {
          this.profile = profile;
          this.getProductList();
        },
        error: () => {
          if (!this.liffService.isLoggedIn()) {
            this.liffService.login('/products');
          }
        }
      });
  }

  filterProducts(search: string): void {
    if (!search) {
      this.filteredProducts = [...this.products];
    } else {
      this.filteredProducts = this.products.filter(product =>
        product.name.toLowerCase().includes(search.toLowerCase())
      );
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getProductList(): void {
    this.productService.getProduct(this.profile.accessToken!)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (products) => {
          this.products = products.map(p => ({ ...p, quantity: 0 }));
          this.filteredProducts = [...this.products];
          this.patchSelectedProducts();
        },
        error: (e) => console.error(e)
      });
  }

  patchSelectedProducts() {
    const selectedProductsJson = this.storageService.getItem('selectedProducts');
    if (selectedProductsJson) {
      const selectedProducts: SelectedProduct[] = JSON.parse(selectedProductsJson);
        selectedProducts.forEach(sp => {
          this.products.forEach(p => {
            if (p.id === sp.id) p.quantity = sp.quantity;
          })
        });
        this.updateSelectedProducts();
    }
  }

  increaseQuantity(product: SelectedProduct): void {
    product.quantity++;
    this.updateSelectedProducts();
  }

  decreaseQuantity(product: SelectedProduct): void {
    if (product.quantity > 0) {
      product.quantity--;
      this.updateSelectedProducts();
    }
  }

  updateSelectedProducts(): void {
    this.hasSelectedProducts = this.products.filter(p => p.quantity > 0);
    this.calculateSummaryPrice();
  }

  calculateSummaryPrice(): void {
    this.summaryPrice = this.hasSelectedProducts.reduce((total, { price, quantity }) => total + price * quantity, 0);
  }

  checkout(): void {
    this.storageService.setItem('selectedProducts', JSON.stringify(this.hasSelectedProducts));
    this.router.navigate(['/order/checkout']);
  }

  onSearch(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.searchInput.next(input.value);
  }

  clearSearch() {
    this.searchText = '';
    this.filterProducts('');
  }
}
