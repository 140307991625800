@if (profile) {
<div class="page-container">
  <div class="page-content">
    <div class="search-box">
      <div class="search-group">
        <img class="icon" src="assets/images/icons/icon_search.svg">
        <input class="input-search" (input)="onSearch($event)" [(ngModel)]="searchText">
        <img *ngIf="searchText" class="icon-clear" src="assets/images/icons/icon_clear.svg" (click)="clearSearch()">
      </div>
    </div>
    <form *ngIf="filteredProducts" class="product-box">
      <div class="product-list">
        @for (product of filteredProducts; track product; let index = $index) {
        <div class="product">
          <div class="form-product">
            <div class="image">
              <div class="chip">{{ product.price | currency }}</div>
            </div>
            <p class="title">{{ product.name }}</p>
            <input class="input-field-small" placeholder="Enter quantity..." type="number" [(value)]="product.quantity">
            <div class="buttom-group">
              <button class="button-icon" (click)="decreaseQuantity(product)">-</button>
              <button class="button-icon" (click)="increaseQuantity(product)">+</button>
            </div>
          </div>
        </div>
        }
      </div>
    </form>
  </div>
  <div class="page-bottom-action">
    <div class="shopping-cart">
      <img class="icon" src="assets/images/icons/icon_shopping_cart.svg">
      <div class="badge">{{ hasSelectedProducts.length }}</div>
    </div>
    <p class="total-price">{{ summaryPrice | currency }}</p>
    <button class="button-secondary" (click)="checkout()"
      [disabled]="hasSelectedProducts.length === 0">Checkout</button>
  </div>
</div>
}