@if (order) {
    <div class="page-container">
        <div class="page-content">
            <div class="restaurant-content">
                <div class="field-display-group">
                    <div class="chip">{{ order.status }}</div>
                    <label class="label">Restaurant Name :</label>
                    <p class="value">{{ order.customer.restaurant.name }}</p>
                </div>
                <div class="field-display-group column">
                    <label class="label">Shipping Detail :</label>
                    <div class="value-group">
                        <p class="value">Contact person: {{ order.customer.name }}</p>
                        <p class="value">Telephone: {{ order.customer.phoneNo || '-' }}</p>
                    </div>
                </div>
            </div>
            <div class="order-list">
                @for (order of order.orderDetails; track $index) {
                <div class="order-row">
                    <div class="image"></div>
                    <p class="name">{{ order.product.name || '-' }}</p>
                    <p class="price">{{ order.unitPrice | currency }}</p>
                    <p class="quantity">x{{order.quantity }}</p>
                </div>
                }
            </div>
    
        </div>
        <div class="page-bottom-action">
            <div class="total-price">Total {{ order.totalAmount | currency }}</div>
            <button class="button-secondary" (click)="createInvoice()">Create Invoice</button>
        </div>
    </div>
}