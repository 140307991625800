import { Routes } from '@angular/router';
import { OnboardComponent } from './pages/onboard/onboard.component';
import { ProductListComponent } from './pages/product-list/product-list.component';
import { OrderCheckoutComponent } from './pages/order-checkout/order-checkout.component';
import { OrderStatusComponent } from './pages/order-status/order-status.component';
import { CustomerProfileComponent } from './pages/customer-profile/customer-profile.component';
import { OrderComponent } from './pages/order/order.component';
import { AdminOrderListComponent } from './admin/pages/admin-order-list/admin-order-list.component';
import { QuickbookCallbackComponent } from './admin/pages/quickbook-callback/quickbook-callback.component';
import { QuickbookConnectComponent } from './admin/pages/quickbook-connect/quickbook-connect.component';
import { AdminProductsSyncComponent } from './admin/pages/admin-products-sync/admin-products-sync.component';
import { SuccessComponent } from './pages/success/success.component';
import { AdminOrderDetailComponent } from './admin/pages/admin-order-detail/admin-order-detail.component';

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: OrderComponent
      },
    ]
  },
  {
    path: 'onboard',
    children: [
      {
        path: '',
        component: OnboardComponent,
        title: 'Register'
      },
    ]
  },
  {
    path: 'customer',
    children: [
      {
        path: 'profile',
        component: CustomerProfileComponent,
        title: 'Profile'
      },
    ]
  },
  {
    path: 'products',
    component: ProductListComponent,
    title: 'Product'
  },
  {
    path: 'success',
    component: SuccessComponent,
    title: 'Success'
  },
  {
    path: 'order',
    children: [
      {
        path: '',
        component: OrderComponent,
        title: 'Order'
      },
      {
        path: 'checkout',
        component: OrderCheckoutComponent,
        title: 'Checkout'
      },
      {
        path: 'status',
        component: OrderStatusComponent
      }
    ]
  },
  {
    path: 'admin',
    children: [
      {
        path: 'login',
        component: QuickbookConnectComponent
      },
      {
        path: 'callback',
        component: QuickbookCallbackComponent
      },
      {
        path: 'orders',
        component: AdminOrderListComponent,
      },
      {
        path: 'orders/:id',
        component: AdminOrderDetailComponent,
      },
      {
        path: 'products-sync',
        component: AdminProductsSyncComponent,
      }
    ]
  }
];