import { Component, OnDestroy, OnInit } from '@angular/core'; 
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { AuthenRequest } from '../../../interfaces/authen-request';
import { BearerToken } from '../../../interfaces/bearer-token';
import { RefreshTokenRequest } from '../../../interfaces/refresh-token-request';
import { QuickbookService } from '../../services/quickbook.service';


@Component({
  selector: 'app-quickbook-callback',
  standalone: true,
  imports: [ CommonModule ],
  templateUrl: './quickbook-callback.component.html',
  styleUrl: './quickbook-callback.component.scss'
})
export class QuickbookCallbackComponent implements OnInit, OnDestroy {
  bearer: BearerToken | undefined;
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private quickbookService: QuickbookService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    const authorizationCode = this.activatedRoute.snapshot.queryParams['code'];
    const companyId = this.activatedRoute.snapshot.queryParams['realmId'];

    const authenRequest = { code: authorizationCode, realmId: companyId } as AuthenRequest;
    this.quickbookService.authen(authenRequest)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (_: BearerToken) => {
          this.goToOrdersPage();
        }
      });
  }

  goToOrdersPage() {
    this.router.navigate(['admin', 'orders']);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
