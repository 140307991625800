import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ReferencesService } from '../services/references.service';
import { ReferenceData } from '../interfaces/reference-data';

@Injectable({
  providedIn: 'root'
})
export class ReferencesResolver implements Resolve<ReferenceData> {
  constructor(private service: ReferencesService) {}

  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<ReferenceData> {
    return this.service.load();
  }
}