import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '../../services/env.service';
import { AuthenRequest } from '../../interfaces/authen-request';
import { BearerToken } from '../../interfaces/bearer-token';
import { Observable, tap } from 'rxjs';
import { RefreshTokenRequest } from '../../interfaces/refresh-token-request';
import { SessionStorageService } from '../../services/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class QuickbookService {
  constructor(
    private http: HttpClient,
    private env: EnvService,
    private storage: SessionStorageService
  ) { }

  get token(): BearerToken | undefined {
    const bearerTokenString = this.storage.getItem('bearerToken');
    if (bearerTokenString) {
      const bearerToken = JSON.parse(bearerTokenString) as BearerToken;
      return bearerToken;
    } else {
      return undefined;
    }
  }

  goLogin() {
    const qb = this.env.config.quickbook;
    window.location.href = `${qb.authEndpoint}?client_id=${qb.clientId}&response_type=${qb.responseType}&scope=${qb.scope}&redirect_uri=${qb.redirectUri}&state=${qb.state}`;
  }

  authen(authenRequest: AuthenRequest): Observable<BearerToken> {
    const url = `${this.env.config.apiUrl}/admin/authen`;
    return this.http.post<BearerToken>(url, authenRequest)
      .pipe(
        tap((token: BearerToken) => {
          this.storage.setItem('bearerToken', JSON.stringify(token));
        })
      );
  }

  refreshToken(refreshToken: RefreshTokenRequest): Observable<BearerToken> {
    const url = `${this.env.config.apiUrl}/admin/token/refresh`;
    return this.http.post<BearerToken>(url, refreshToken)
      .pipe(
        tap((token: BearerToken) => {
          this.storage.setItem('bearerToken', JSON.stringify(token));
        })
      );
  }
}
